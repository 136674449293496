<template>
  <div class="fd-selector fd-validation" :class="{ disabled: disabled }">
    <input
      :value="value"
      type="text"
      hidden
      :required="required"
      :disabled="disabled"
      @invalid="$emit('invalid')"
    />
    <slot name="beforeOption" :hasError="hasError"></slot>
    <slot
      v-for="option in options"
      name="option"
      :option="option"
      :active="isSelected(option)"
      :hasError="hasError"
      :select="
        (option) => {
          if (!disabled) {
            hasError = false;
            $emit('input', optionValue(option));
          }
        }
      "
      :disabled="disabled"
    ></slot>
    <slot name="afterOption" :hasError="hasError"></slot>
  </div>
</template>

<script>
import validator from "./Validator/mixin/validatorMixin";

export default {
  components: {},
  mixins: [validator],
  props: {
    value: {
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    optionValue: {
      type: Function,
      default: (option) => option
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    isSelected(option) {
      return Object.is(this.optionValue(option), this.value);
    }
  }
};
</script>

<style lang="scss"></style>
